import React from 'react';
import Layout from '../components/Layout';

const Fragile = () => (
  <Layout>
    <article id="main">
    <header>
      <h2>Carlsbad movers fragile packing service</h2>
      <p>Have professional movers package all your fragile items</p>
    </header>
    <section className="wrapper style5">
      <div className="inner">
        <section>

            <h4>Moving means moving things the right way!</h4>
            <p> So maybe the <a href="/fullpacking">full packing service</a> doesn't make sense for you. But you still have a lot of things
            that you don't really want to pack yourself. Maybe the fragile packing service is the best choice for you. With the fragile packing service
            we take care of packaging all of the big glass, fabric, stone, and art for one flat fee.  Now the fragile packing service does not include dishes but we can always
            take care of packing all of the china as an itemized bill per box of china packed. Talk to your moving represtantive now and find out which packing service is the the best choice for you.</p>

        </section>
      </div>
      </section>
      </article>
  </Layout>
);
export default Fragile;
